import { Blue600, DarkerTheme, LightTheme } from "../../Styles/Colors";
import StyledToolTip from "../ToolTip/ToolTip";

export const stateFields = (
  handleDebounce,
  handleAutoCompleteSelect,
  getValues
) => {
  return [
    {
      name: "name",
      type: "autoComplete",
      placeholder: "שם המוצר",
      func: handleDebounce,
      required: true,
      defaultValue: getValues && getValues("name.prodName"),
      requiredMessage: "אנא הכנס ערך מתאים",
      label: "שם המוצר",
      validationFunc: (value) => {
        if (value <= 1) {
          return false;
        }
        return true;
      },
      tooltip: (
        <StyledToolTip
          background={LightTheme}
          color={DarkerTheme}
          iconColor={Blue600}
          content={"שם המוצר"}
        />
      ),
    },
    {
      name: "amount",
      type: "number",
      maxLength: 2,
      label: "יחידות",
      required: true,
      defaultValue: getValues && getValues("amount"),
      requiredMessage: "אנא הכנס ערך מתאים",
      placeholder: "יחידות",
      func: handleDebounce,
      validationFunc: (value) => {
        if (value === 0) {
          return false;
        }
        return true;
      },
      tooltip: (
        <StyledToolTip
          background={LightTheme}
          color={DarkerTheme}
          iconColor={Blue600}
          content={"כמה יחידות להביא מהמוצר"}
        />
      ),
    },

    {
      name: "desc",
      type: "text",
      label: "תיאור נוסף",
      placeholder: "תיאור נוסף",
      func: handleDebounce,
      defaultValue: getValues && getValues("desc"),
      validationFunc: null,
      tooltip: (
        <StyledToolTip
          background={LightTheme}
          color={DarkerTheme}
          iconColor={Blue600}
          content={"תיאור נוסף של המוצר במידת הצורך"}
        />
      ),
    },
    {
      name: "assignee",
      type: "select",
      boxHeader: "מי מביא",
      placeholder: null,
      func: null,
      validationFunc: null,
      defaultValue: getValues && getValues("assignee"),
      tooltip: (
        <StyledToolTip
          background={LightTheme}
          color={DarkerTheme}
          iconColor={Blue600}
          content={"מי מביא את המוצר"}
        />
      ),
    },
  ];
};
