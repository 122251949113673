import { useTranslation } from "react-i18next";
import ActionButton from "../../../Components/Buttons/ActionButton";
import { MainGreen, MainPink } from "../../../Styles/Colors";
import { BoxSize } from "../../../Styles/Styles";

export const ModalButtons = ({ finishShopping, finalAmount, onClose }) => {
  const { t } = useTranslation();
  return (
    <BoxSize isInvisible padding="0" margin="0">
      <ActionButton
        button={{
          bg: MainPink,
          colorScheme: "pink",
          content: t("cancel"),
        }}
        onClick={() => {
          onClose();
        }}
      />
      <ActionButton
        button={{
          bg: MainGreen,
          colorScheme: "green",
          content: t("save"),
          disable: finalAmount.length === 0,
        }}
        onClick={finishShopping}
      />
    </BoxSize>
  );
};
