/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { BreakLine, Flex } from "../../Styles/Styles";
import { isEmpty } from "../../Utils/objectEmpty";
import HeaderWrapper from "../../Components/Headers/Headers";
import { White } from "../../Styles/Colors";
import ModalWrapper from "../../Components/Modal/Modal";
import { useDisclosure } from "@chakra-ui/react";
import SearchInput from "../../Components/Input/SearchInput";
import ScrollableTabs from "../../Components/Tabs/ScrollableTabs";
import { baseFetch } from "../../Utils/baseFetch";
import io from "socket.io-client";
import { useHistory } from "react-router-dom";
import useSingleToast from "../../Utils/Hooks/useSingleToast";
import { tabs } from "./Tabs";
import { PageButtons } from "./PageButtons";
import { ModalButtons } from "./ModalDisplay/ModalButtons";
import { addProductCon } from "./ModalDisplay/AddProductContent";
import { finishShoppingCon } from "./ModalDisplay/FinishShoppingContent";
import { useSession } from "@clerk/clerk-react";
import { useTranslation } from "react-i18next";
import ErrorComponents from "../../Modules/Errors/ErrorsComponent";

const List = () => {
  const [currentList, setCurrentList] = useState({});
  const [pageHeader, setPageHeader] = useState("");
  const [loading, setLoading] = useState(false);
  const addProduct = useRef(false);
  const [finalAmount, setFinalAmount] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [products, setProducts] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editableProduct, setEditableProduct] = useState({});
  const { showSuccessToast, showErrorToast } = useSingleToast();
  let history = useHistory();
  let { id } = useParams();
  const { session } = useSession();
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const socket = io("http://localhost:4000", {
    withCredentials: true,
  });

  const fetchListItemsByIds = useCallback(
    async (itemIds) => {
      try {
        const { data, error } = await baseFetch(
          `/api/lists/listItems/byIds`,
          session,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ itemIds: itemIds }),
          }
        );
        if (error) return setError(error);

        setProducts(data);
      } catch (error) {
        console.error("Failed to fetch list items by IDs:", error);
        return [];
      }
    },
    [session]
  );

  const fetchActiveList = useCallback(
    async (id) => {
      try {
        setLoading(true);
        const { data, error } = await baseFetch(`/api/lists/${id}`, session);
        if (error) return setError(error);

        setCurrentList(data);
        fetchListItemsByIds(data.items);
        setPageHeader(data.name);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch list:", error);
      }
    },
    [fetchListItemsByIds, session]
  );

  const fetchIdFromUrl = useCallback(() => {
    fetchActiveList(id);
  }, [fetchActiveList, id]);

  useEffect(() => {
    fetchIdFromUrl();
  }, [fetchIdFromUrl]);

  const groupedByAssignee = (products) => {
    return products.reduce((acc, product) => {
      if (!acc[product.assignee]) {
        acc[product.assignee] = [];
      }
      acc[product.assignee].push(product);
      return acc;
    }, {});
  };

  const assigneeGroupedProducts = groupedByAssignee(products || []);

  const openAddProductModal = useCallback(() => {
    addProduct.current = true;
    onOpen();
  }, [onOpen]);

  const saveProductToList = useCallback(
    async (prodData) => {
      try {
        const { data, error } = await baseFetch(
          `/api/lists/${currentList._id}/addProduct`,
          session,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              productId: prodData.id,
              amountOfUnits: prodData.amount,
              assignee: prodData.assignee,
              description: prodData.desc,
            }),
          }
        );
        if (error) return setError(error);

        setCurrentList(data);
        showSuccessToast(t("productSavedSuccessfuly"));
        onClose();
        fetchListItemsByIds(data.items);
        // socket.emit("productAdded", { listId: currentList._id, product: prodData });
      } catch (error) {
        console.error("Failed to add product:", error);
        showErrorToast(t("errorWhenSavingProduct"));
      }
    },
    [
      currentList._id,
      fetchListItemsByIds,
      onClose,
      session,
      showErrorToast,
      showSuccessToast,
      t,
    ]
  );

  const finishShopping = async () => {
    try {
      const { data, error } = await baseFetch(
        `/api/lists/${currentList._id}/finishShopping`,
        session,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ status: false, totalPrice: finalAmount }),
        }
      );
      if (error) return setError(error);
      setCurrentList(data);
      showSuccessToast(t("shoppingEnded"));
      onClose();
      history.push(`/`);
    } catch (error) {
      console.error("Failed to finish shopping:", error);
      showSuccessToast(t("errorWhenFinishingShopping"));
    }
  };

  const handleCloseModal = () => {
    setFinalAmount("");
  };

  const onRemoveProduct = useCallback(
    async (product) => {
      try {
        const { data, error } = await baseFetch(
          `/api/lists/${currentList._id}/products/${product._id}`,
          session,
          {
            method: "DELETE",
          }
        );
        if (error) return setError(error);

        if (!isEmpty(data)) {
          setCurrentList(data);
          socket.emit("removeProduct", {
            listId: currentList._id,
            productId: product._id,
          });
          const updatedProducts = products.filter((p) => p._id !== product._id);
          setProducts(updatedProducts);
          showSuccessToast(t("productDeletedSuccessfuly"));
        }
      } catch (error) {
        console.error("Failed to remove product:", error);
        showErrorToast(t("errorWhenDeletingProduct"));
      }
    },
    [
      currentList._id,
      products,
      session,
      showErrorToast,
      showSuccessToast,
      socket,
      t,
    ]
  );

  const onEditProduct = useCallback(
    (product) => {
      setEditableProduct(product);
      setEdit(true);
      onOpen();
    },
    [onOpen]
  );

  const editProductInList = async (product, editedData) => {
    try {
      const { data, error } = await baseFetch(
        `/api/lists/${currentList._id}/products/${product._id}/edit`,
        session,
        {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: editedData.name,
            amountOfUnits: editedData.amount,
            assignee: editedData.assignee,
            description: editedData.desc,
          }),
        }
      );
      onClose();
      if (error) return setError(error);
      showSuccessToast(t("productChangedSuccessfuly"));
      // socket.emit("productEdited", {
      //   listId: currentList._id,
      //   product: updatedProduct,
      // });
    } catch (error) {
      console.error("Failed to edit product:", error);
      showSuccessToast(t("errorWhenChangingProduct"));
    }
  };

  const switchStatus = (status) => {
    if (status === "pending") return "retrieved";
    if (status === "retrieved") return "pending";
  };

  const onCheckProduct = useCallback(
    async (product) => {
      const status = switchStatus(product.status);
      try {
        const { data, error } = await baseFetch(
          `/api/lists/${currentList._id}/products/${product._id}`,
          session,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              status: status,
            }),
          }
        );
        if (error) return setError(error);
      } catch (error) {
        console.error("Failed to check product:", error);
      }
    },
    [currentList._id, session]
  );

  const filterProducts = useCallback(
    (term) => {
      if (!term) {
        setFilteredProducts([]);
        return;
      }
      const matchingProducts = products.filter((product) =>
        product.productDetails.name.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredProducts(matchingProducts);
    },
    [products]
  );

  useEffect(() => {
    filterProducts(searchTerm);
  }, [filterProducts, searchTerm]);

  useEffect(() => {
    socket.on("productStatusUpdated", (data) => {
      const updatedProducts = products.map((p) =>
        p._id === data.listItemId ? { ...p, status: data.status } : p
      );
      setProducts(updatedProducts);
    });

    socket.on("productAdded", (data) => {
      const newItemId = data.product._id;
      const previousItems =
        currentList && currentList.items ? currentList.items : [];
      fetchListItemsByIds([...previousItems, newItemId]);
    });

    socket.on("productRemoved", (data) => {
      const updatedProducts = products.filter((p) => p._id !== data.productId);
      setProducts(updatedProducts);
    });
    socket.on("productEdited", (data) => {
      const updatedProducts = products.map((p) =>
        p.productDetails._id === data.product.productId
          ? {
              ...p,
              ...data.product,
              productDetails: {
                ...p.productDetails,
                _id: data.product.productId,
              },
            }
          : p
      );
      setProducts(updatedProducts);
    });
    return () => socket.disconnect();
  }, [currentList, products, socket]);

  const resetAddProductModal = () => {
    setEdit(false);
    setEditableProduct({});
  };

  const tabsData = tabs(
    searchTerm,
    filteredProducts,
    products,
    onRemoveProduct,
    onCheckProduct,
    loading,
    onEditProduct,
    assigneeGroupedProducts,
    t
  );

  const addProductContent = addProductCon(
    edit,
    editProductInList,
    editableProduct,
    saveProductToList,
    t
  );

  const finishShoppingContent = finishShoppingCon(setFinalAmount, t);

  const modalButtons = (
    <ModalButtons
      finishShopping={finishShopping}
      finalAmount={finalAmount}
      onClose={onClose}
    />
  );

  if (error) {
    return <ErrorComponents error={error} />;
  }

  return (
    <HeaderWrapper header={pageHeader}>
      <BreakLine />
      <Flex
        background="transparent"
        style={{ marginBottom: "2.5rem" }}
        justify="center"
      >
        <PageButtons
          currentList={currentList}
          addProduct={addProduct}
          onOpen={onOpen}
          resetAddProductModal={resetAddProductModal}
          openAddProductModal={openAddProductModal}
        />
        <SearchInput
          input={{ bg: White }}
          onSearch={(value) => {
            setSearchTerm(value);
            setSelectedTab(0);
          }}
        />

        <ScrollableTabs
          tabs={tabsData}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        {/* <BottomBar content={pageButtons} /> */}
        {isOpen && (
          <ModalWrapper
            onSave={() => {}}
            modalInfo={
              addProduct.current || edit
                ? addProductContent
                : finishShoppingContent
            }
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            clearInputs={!addProduct.current && handleCloseModal}
            buttons={addProduct.current || edit ? null : modalButtons}
          />
        )}
      </Flex>
    </HeaderWrapper>
  );
};
export default List;
