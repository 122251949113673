import { Skeleton } from "@chakra-ui/react";
import { LightTheme } from "../../Styles/Colors";

const ListSkeleton = () => {
  return (
    <div
      style={{
        padding: "1rem",
        border: `1px solid ${LightTheme}`,
        marginBottom: "10px",
        borderRadius: "15px",
      }}
    >
      <Skeleton
        width="100%"
        height="50px"
        marginBottom="10px"
        borderRadius="15px"
      />
      <Skeleton
        width="100%"
        height="100px"
        marginBottom="10px"
        borderRadius="15px"
      />
    </div>
  );
};

export default ListSkeleton;
