import ActionButton from "../../Components/Buttons/ActionButton";
import { MainBlue } from "../../Styles/Colors";
import { BoxSize } from "../../Styles/Styles";
import { isEmpty } from "../../Utils/objectEmpty";
import { BsCartCheck } from "react-icons/bs";
import { BiCartAdd } from "react-icons/bi";

export const PageButtons = ({
  currentList,
  addProduct,
  onOpen,
  resetAddProductModal,
  openAddProductModal,
}) => {
  return (
    <BoxSize isInvisible padding="0" margin="0">
      {!isEmpty(currentList) && (
        <ActionButton
          button={{
            bg: "transparent",
            icon: <BsCartCheck size="1.7rem" color={MainBlue} />,
          }}
          onClick={() => {
            addProduct.current = false;
            onOpen();
          }}
        />
      )}
      <ActionButton
        button={{
          bg: "transparent",
          icon: <BiCartAdd size="1.9rem" color={MainBlue} />,
        }}
        onClick={() => {
          resetAddProductModal();
          openAddProductModal();
        }}
      />
    </BoxSize>
  );
};
