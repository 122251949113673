import styled from "styled-components";
import { Checkbox } from "@chakra-ui/react";
import { DarkerTheme, MainBlue, White } from "../../Styles/Colors";

export const StyledCheckbox = styled(Checkbox)`
	span {
		padding: 1.2rem;
		border-radius: 50px;
		border: 2px solid ${MainBlue};
	}
	svg {
		width: 2.2em;
	}
	/* This targets the visual checkbox span when it's checked */
	.chakra-checkbox__control[data-checked] {
		background-color: ${MainBlue} !important;
		border-color: ${MainBlue} !important;
	}
`;

export const StyledUserSpan = styled.span`
	border-radius: 50px;
	background: ${White};
	padding: 3px 6px;
	color: ${DarkerTheme};
	position:absolute;
	right:.5rem;
	font-weight:600;
	margin: .2rem 0rem;

`;
