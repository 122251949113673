/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import HeaderWrapper from "../Components/Headers/Headers";
import DynamicTable from "../Components/Table/Table";
import { MainBlue, MainPink } from "../Styles/Colors";
import ModalWrapper from "../Components/Modal/Modal";
import { Box } from "@chakra-ui/react";
import ActionButton from "../Components/Buttons/ActionButton";
import { BreakLine } from "../Styles/Styles";
import { baseFetch } from "../Utils/baseFetch";
import { useHistory } from "react-router-dom";
import { FiMoreVertical } from "react-icons/fi";
import { useSession } from "@clerk/clerk-react";
import { useTranslation } from "react-i18next";
import ErrorComponents from "../Modules/Errors/ErrorsComponent";


const ListsPage = () => {
  const [lists, setLists] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalContent, setModalContent] = useState({});
  const [selectedList, setSelectedList] = useState("");
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { session } = useSession();
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const pageName = t("lists");

  const columns = [
    {
      Header: t("more"),
      accessor: "actions",
      Cell: ({ row }) => (
        <button onClick={() => handleActionClick(row.original)}>
          <FiMoreVertical color={MainBlue} size="1rem" />
        </button>
      ),
      sortable: false,
    },
    { Header: t("totalPrice"), accessor: "totalPrice", sortable: true },
    { Header: t("numberOfProducts"), accessor: "nProducts", sortable: true },
    { Header: t("name"), accessor: "name", sortable: true },
  ];

  const productColumns = useMemo(
    () => [
      // {
      //   Header: "מי הביא",
      //   accessor: "assignee",
      // },
      {
        Header: t("amount"),
        accessor: "amountOfUnits",
      },
      {
        Header: t("product"),
        accessor: (row) => row.productDetails.name,
        id: "productDetailsName",
      },
    ],
    [t]
  );

  const createNewList = async () => {
    try {
      const { data, error } = await baseFetch("/api/lists/new", session, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
      if (error) return setError(error);
      if (data._id) {
        history.push(`/list:${data._id}`);
      } else {
        console.error("Failed to create a new list.");
      }
    } catch (error) {
      console.error("Error creating a new list:", error);
    }
  };

  const fetchListItemsByIds = async (itemIds) => {
    try {
      const { data, error } = await baseFetch(
        `/api/lists/listItems/byIds`,
        session,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ itemIds: itemIds }),
        }
      );
      if (error) return setError(error);
      return data;
    } catch (error) {
      console.error("Failed to fetch list items by IDs:", error);
      return [];
    }
  };

  const fetchAllLists = useCallback(async () => {
    try {
      const { data, error } = await baseFetch("/api/lists/allLists", session);
      if (error) return setError(error);
      const processedLists = data.map((list) => ({
        ...list,
        nProducts: list.items.length,
        totalPrice: list.finalPrice === null ? "0" : list.finalPrice,
      }));

      setLists(processedLists);
      setLoading(false);
    } catch (e) {
      console.error("Error fetching the current list:", e);
      setLoading(false);
    }
  },[session]);

  useEffect(() => {
    if (lists && lists.length === 0) {
      fetchAllLists();
    }
  }, [lists, fetchAllLists]);

  const handleActionClick = async (list) => {
    const productDetails = await fetchListItemsByIds(list.items);
    const content = {
      header: list.name,
      body: (
        <>
          <Box overflowX="auto" padding="0">
            <DynamicTable columns={productColumns} data={productDetails} />
          </Box>
        </>
      ),
    };
    setModalContent(content);
    setSelectedList(list);
    onOpen();
  };

  const deleteList = async (list) => {
    setLoading(true);
    try {
      const { data, error } = await baseFetch(`/api/lists/${list._id}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });
      if (error) return setError(error);
      const updatedLists = lists.filter((l) => l._id !== list._id);
      setLists(updatedLists);
      setLoading(false);
      onClose();
    } catch (error) {
      console.error("Error deleting the list:", error);
    }
  };

  if (error) {
    return <ErrorComponents error={error} />;
  }

  return (
    <HeaderWrapper header={pageName}>
      <>
        <DynamicTable
          columns={columns}
          data={lists}
          handleActionClick={handleActionClick}
          loading={loading}
        />
        <BreakLine />
        {/* <Link to="/list">
          <ActionButton
            button={{
              content: "צור רשימה חדשה",
              colorScheme: "pink",
              bg: MainPink,
            }}
            onClick={createNewList}
          />
        </Link> */}
        {isOpen && (
          <ModalWrapper
            onSave={() => {}}
            modalInfo={modalContent}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            buttons={
              <>
                <ActionButton
                  onClick={() => deleteList(selectedList)}
                  button={{
                    bg: MainPink,
                    colorScheme: "pink",
                    content: t("deleteList"),
                  }}
                />
                {/* <VerticalBreakLine />
                <ActionButton
                  onClick={() => deleteList(selectedList)}
                  button={{
                    bg: MainOrange,
                    colorScheme: "orange",
                    content: "ערוך רשימה",
                  }}
                /> */}
              </>
            }
          />
        )}
      </>
    </HeaderWrapper>
  );
};

export default ListsPage;
