import { isEmpty } from "../../../Utils/objectEmpty";
import ShoppingBagLogo from "../../../Assets/Images/shoppingbag.png";
import CombinedLogo from "../../../Assets/Images/combined.png";

import { MainGreen, MainPink } from "../../../Styles/Colors";

const actionsView = (currentList = {}, hasActiveList, createNewList, t) => {
  const listDescription = isEmpty(currentList) ? "" : t("watchActiveList");

  return [
    {
      content: `${isEmpty(currentList) ? "" : currentList.name} | ${
        isEmpty(currentList) ? "" : currentList.items.length
      } ${t("products")}`,
      imageSource: CombinedLogo,
      imageSize: "5rem",
      imagePadding: "0",
      link: `/list/${currentList._id ? currentList._id : ""}`,
      text: [
        listDescription,
        `${isEmpty(currentList) ? t("noActiveListATM") : ""} `,
      ],
      button: {
        colorScheme: "green",
        bg: MainGreen,
        text: t("watchActiveList"),
        disabled: !hasActiveList,
      },
    },
    {
      content: t("createNewList"),
      imageSource: ShoppingBagLogo,
      imageSize: "2.7rem",
      text: [
        `${
          !isEmpty(currentList) ? t("activeListExists") : t("createNewList")
        } `,
      ],
      button: {
        colorScheme: "pink",
        bg: MainPink,
        text: t("createNewList"),
        onClick: hasActiveList ? null : createNewList,
        disabled: hasActiveList,
      },
      link: "/list",
      onClick: hasActiveList ? null : createNewList,
    },
  ];
};
export default actionsView;
