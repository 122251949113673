import { FaEdit, FaTrash } from "react-icons/fa";
import HamburgerMenu from "../../Modules/Navigation/Hamburger/Hamburger";
import {
  DarkTheme,
  InputColor,
  MainBlue,
  MainOrange,
  MainPink,
  White,
} from "../../Styles/Colors";
import { BoxSize, Flex, Shadow } from "../../Styles/Styles";
import { categories } from "../../mocks/categories";
import { StyledCheckbox, StyledUserSpan } from "./Style";
import { FiMoreVertical } from "react-icons/fi";

const ProductCard = ({ product, onCheck, onRemove, onEdit }) => {
  const isChecked = product.status === "retrieved";

  const getCategoryByEnglishName = (name) => {
    return categories.find((cat) => cat.englishName === name);
  };

  const category = product.productDetails
    ? getCategoryByEnglishName(product.productDetails.category)
    : null;

  return (
    <>
      <Flex
        alignItems="center"
        radius="15px"
        border={`1px solid ${InputColor}`}
      >
        <BoxSize
          flexSize="100%"
          textAlign="center"
          background={category ? category.backgroundColor : undefined}
          style={{
            borderBottomRightRadius: "0",
            borderBottomLeftRadius: "0",
            borderTopRightRadius: "15px",
            borderTopLeftRadius: "15px",
            margin: "0",
            padding: "0.3rem",
            position: "relative",
          }}
        >
          <span
            style={{
              background: White,
              padding: "3px 6px",
              borderRadius: "15px",
              fontWeight: 600,
              color: DarkTheme,
              margin: ".2rem 0rem",
              boxShadow: Shadow,
              display: "inline-block",
            }}
          >
            {category ? category.hebrewName : ""}
          </span>
          <StyledUserSpan>{product.assignee}</StyledUserSpan>
        </BoxSize>
        <BoxSize flexSize="2 0 65%" margin="0" padding=".2rem" display="flex">
          <HamburgerMenu
            icon={
              <FiMoreVertical
                size="1.8rem"
                color={MainBlue}
                cursor={"pointer"}
              />
            }
            menuItems={[
              {
                name: "ערוך מוצר",
                icon: <FaEdit color={MainOrange} size="1.3rem" />,
                onClick: () => onEdit(product),
              },
              {
                name: "מחק מוצר",
                icon: <FaTrash color={MainPink} size="1.2rem" />,
                onClick: () => onRemove(product),
              },
            ]}
          />
          <Flex justify="flex-end">
            <BoxSize fontSize="1.1rem" color="black" textAlign="right">
              x{product.amountOfUnits} {product.productDetails.name}
              <p
                style={{
                  border: `1px solid ${InputColor}`,
                  borderRadius: "15px",
                }}
              >
                {product.description}
              </p>
            </BoxSize>
          </Flex>
          {/* <Flex justify="space-between">
            <BoxSize fontSize="1.1rem" color="black">
              x{product.amountOfUnits} {product.productDetails.name}
            </BoxSize>
          </Flex> */}
        </BoxSize>
        <BoxSize color="black" flexSize="1 0 0%" textAlign="center" display="flex" justifyContent="flex-end" padding="1rem .5rem">
          <StyledCheckbox
            size="lg"
            width={"fit-content"}
            isChecked={isChecked}
            onChange={() => onCheck(product)}
          />
        </BoxSize>
      </Flex>
    </>
  );
};
export default ProductCard;
