/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import InputComp from "../Input/Input";
import { useTable, usePagination, useSortBy, useFilters } from "react-table";
import {
  PaginationWrapper,
  StyledTable,
  TableButton,
  TableCell,
  TableWrapper,
} from "./Style";
import { SmallBreakLine } from "../../Styles/Styles";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import ListSkeleton from "../Skeletons/ListSkeleton";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MainBlue } from "../../Styles/Colors";

const DynamicTable = ({ columns, data, handleActionClick, loading }) => {
  const [filterInput, setFilterInput] = useState("");
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 5 } },
    useFilters,
    useSortBy,
    usePagination
  );

  const onSearch = (value) => {
    setFilterInput(value);
    setFilter("name", value);
  };

  if (loading) return <ListSkeleton />;
  if (data.length === 0) return "Couldnt find items...";

  return (
    <TableWrapper>
      <InputComp input={{ placeholder: "...חפש" }} onChange={onSearch} />
      <SmallBreakLine />
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.sortable ? (
                      column.isSorted ? (
                        column.isSortedDesc ? (
                          <IoIosArrowDown
                            color={MainBlue}
                            style={{ display: "inline", margin: "0 0.2rem" }}
                          />
                        ) : (
                          <IoIosArrowUp
                            color={MainBlue}
                            style={{ display: "inline", margin: "0 0.2rem" }}
                          />
                        )
                      ) : (
                        "" // No arrow if not sorted
                      )
                    ) : (
                      "" // No arrow if not sortable
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <TableCell {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </TableCell>
                ))}
              </tr>
            );
          })}
        </tbody>
      </StyledTable>

      {/* Pagination */}
      <PaginationWrapper>
        <TableButton
          colorScheme="blue"
          onClick={nextPage}
          disabled={!canNextPage}
        >
          <FaChevronLeft />
        </TableButton>

        <span>
          עמוד{" "}
          <strong>
            {pageIndex + 1} מ {Math.ceil(data.length / pageSize)}
          </strong>
        </span>
        <TableButton
          colorScheme="blue"
          onClick={previousPage}
          disabled={!canPreviousPage}
        >
          <FaChevronRight />
        </TableButton>
      </PaginationWrapper>
    </TableWrapper>
  );
};

export default DynamicTable;
