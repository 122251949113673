/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Input } from "@chakra-ui/react";
import {
  DarkerTheme,
  InputColor,
  MainBlue,
  MainPink,
} from "../../Styles/Colors";
import { Button } from "@chakra-ui/react";
import { Flex } from "../../Styles/Styles";
import { FormControl, FormLabel } from "@chakra-ui/react";

const CounterInput = ({ input, handleCountChange }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if(input && input.defaultValue && count !== input.defaultValue) {
      setCount(input.defaultValue) 
      handleCountChange(input.defaultValue);
    }
  },[handleCountChange, input])

  const handleIncrement = () => {
    const newCount = count + 1;
    setCount(newCount);
    handleCountChange(newCount);
  };

  const handleDecrement = () => {
    const newCount = Math.max(0, count - 1);
    setCount(newCount);
    handleCountChange(newCount);
  };

  return (
    <FormControl>
      <FormLabel dir="rtl">
        {input && input.label} {input && input.tooltip}
      </FormLabel>
      <Flex justify="flex-end">
        <Button bg={MainPink} colorScheme="pink" onClick={handleDecrement}>
          -
        </Button>
        <Input
          type="number"
          width={"30%"}
          color={DarkerTheme}
          textAlign={"center"}
          margin="0 10px"
          background={InputColor}
          value={count}
          readOnly
        />
        <Button bg={MainBlue} colorScheme="blue" onClick={handleIncrement}>
          +
        </Button>
      </Flex>
    </FormControl>
  );
};

export default CounterInput;
