import { Heading } from "@chakra-ui/react";
import { DarkerTheme } from "../../Styles/Colors";

const HeaderWrapper = ({ children, header }) => {
  return (
    <>
      <Heading
        pt=".8rem"
        fontWeight={"500"}
        fontSize="1.9rem"
        textAlign={"center"}
        color={DarkerTheme}
      >
        {header}
      </Heading>
      {children}
    </>
  );
};

export default HeaderWrapper;
