import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const MyBarChart = ({ dataObject }) => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setData(dataObject);
  }, [dataObject]);


  return (
    <ResponsiveContainer
      width={350}
      height={250}
      style={{ marginLeft: "-2.7rem" }}
    >
      <BarChart data={data} margin={{ top: 5, right: 10, left: 10, bottom: 5 }}>
        <YAxis domain={[0, 100]} />
        <XAxis
          dataKey={"name"}
          interval={0}
          tick={{ fontSize: data?.length > 5 ? "0" :"18px" }}
          tickLine={false}
        />
        <Tooltip formatter={(value) => `${value}%`} />
        <Bar dataKey={t("percentages")} fillAccessorKey="fill" barSize={20} />
      </BarChart>
    </ResponsiveContainer>
  );
};
export default MyBarChart;
