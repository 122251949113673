/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { DarkerTheme, LightTheme, MainGreen } from "../../Styles/Colors";
import { Fragment } from "react";
import { BigBreakLine, Flex } from "../../Styles/Styles";
import ComponentsCard from "../../Components/Cards/ComponentsCard";
import HeaderWrapper from "../../Components/Headers/Headers";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { baseFetch } from "../../Utils/baseFetch";
import { Skeleton } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import actionsView from "./ActionView/ActionView";
import { useAppContext } from "../../Context/AppContext";
import { useSession } from "@clerk/clerk-react";
import { useTranslation } from "react-i18next";
import ErrorComponents from "../../Modules/Errors/ErrorsComponent";
import Statistics from "./Statistics/Statistics";

const Dashboard = () => {
  const [currentList, setCurrentList] = useState({});
  const [loading, setLoading] = useState(true);
  const [hasActiveList, setHasActiveList] = useState(false);
  const history = useHistory();
  const { setActiveList } = useAppContext();
  const { session } = useSession();
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const pageName = (
    <Fragment>
      <span style={{ fontSize: "1.7rem", color: DarkerTheme }}>
        {t("planSmart")},{" "}
        <span style={{ color: MainGreen }}>
          {t("buy")} {t("green")}
        </span>
      </span>
    </Fragment>
  );

  const fetchCurrentList = async () => {
    try {
      const { data, error } = await baseFetch("/api/lists/latest", session);
      if (error) return setError(error);
      if (data !== "") {
        setCurrentList(data);
        setActiveList(data);
      }
      setLoading(false);
      if (data.active === true) {
        setHasActiveList(true);
      }
    } catch (error) {
      console.error("Error fetching the current list:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCurrentList();
  }, []);

  const createNewList = async () => {
    try {
      const { data, error } = await baseFetch("/api/lists/new", session, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
      if (error) return setError(error);
      if (data._id) {
        history.push(`/list/${data._id}`);
      } else {
        console.error("Failed to create a new list.");
      }
    } catch (error) {
      console.error("Error creating a new list:", error);
    }
  };

  const displayedCards = actionsView(
    currentList,
    hasActiveList,
    createNewList,
    t
  );

  if (error) {
    return <ErrorComponents error={error} />;
  }

  if (loading) {
    return (
      <>
        <HeaderWrapper header={pageName}>
          <Flex radius="15px" shadow alignItems="center" padding="1rem">
            {Array.from({ length: displayedCards.length }).map((_, i) => (
              <React.Fragment key={i}>
                <Skeleton
                  height="100px"
                  width="100%"
                  my="10px"
                  borderRadius={"15px"}
                />
              </React.Fragment>
            ))}
          </Flex>
        </HeaderWrapper>
      </>
    );
  }

  return (
    <HeaderWrapper header={pageName}>
      <>
        <Flex radius="15px" shadow alignItems="center" padding="1rem">
          <Flex
            margin="1rem 0rem"
            alignItems="center"
            style={{ border: `1px solid ${LightTheme}`, borderRadius: "15px" }}
          >
            {displayedCards.map((card, i) => (
              <React.Fragment key={i}>
                {card.onClick ? (
                  <>
                    <div style={{ display: "contents" }}>
                      {card.component ? (
                        card.component
                      ) : (
                        <ComponentsCard card={card} />
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <Link to={card.link} style={{ display: "contents" }}>
                      {card.component ? (
                        card.component
                      ) : (
                        <ComponentsCard card={card} />
                      )}
                    </Link>
                  </>
                )}
                <BigBreakLine />
              </React.Fragment>
            ))}
          </Flex>
          <Statistics />
        </Flex>
      </>
    </HeaderWrapper>
  );
};

export default Dashboard;
