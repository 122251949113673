import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import { BreakLine, MobileViewWrapper, Wrapper } from "./Styles/Styles";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes/Routes";
import AppProvider from "./Context/AppContext";
import {
  RedirectToSignIn,
  SignedIn,
  SignedOut,
} from "@clerk/clerk-react";
import "./i18n";
import BottomNav from "./Modules/Navigation/BottomNav";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import theme from "./Components/Theme/Theme";

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("he");
  }, [i18n]);

  return (
    <>
      <SignedIn>
        <ChakraProvider theme={theme}>
          <BrowserRouter>
            <AppProvider>
              <Wrapper>
                <MobileViewWrapper id="allWrapper">
                  <BreakLine />
                  <Routes />
                </MobileViewWrapper>
                <BottomNav />
              </Wrapper>
            </AppProvider>
          </BrowserRouter>
        </ChakraProvider>
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </>
  );
}

export default App;
