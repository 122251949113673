import AddProductForm from "../../../Components/AddProduct/AddProduct";

export const addProductCon = (
  edit,
  editProductInList,
  editableProduct,
  saveProductToList,
  t
) => {
  
  return {
    header: edit ? t("editProduct") : t("addProduct"),
    body: (
      <>
        <AddProductForm
          handleForm={
            edit
              ? (editedData) => editProductInList(editableProduct, editedData)
              : saveProductToList
          }
          isEditMode={edit}
          product={editableProduct}
        />
      </>
    ),
  };
};
