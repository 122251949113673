import InputComp from "../../../Components/Input/Input";
import { SmallBreakLine } from "../../../Styles/Styles";

export const finishShoppingCon = (setFinalAmount,t) => {
  return {
    header: t("finishShopping"),
    body: (
      <>
        <p>{t("areYouSureYoureDone")}</p>
        <SmallBreakLine />
        <InputComp
          input={{ placeholder: t("finalAmount"), type: "number" }}
          onChange={(e) => {
            setFinalAmount(e);
          }}
        />
      </>
    ),
  };
};
