import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
  withCredentials: true,
  baseURL: BASE_URL,
});
export const baseFetch = async (endpoint, session, options = {}) => {
  const defaultHeaders = {
    "Content-Type": "application/json",
  };
  options.headers = { ...defaultHeaders, ...options.headers };

  const params = {
    ...options.params,
    _clerk_session_id: session.id,
    _clerk_organization_id: session.lastActiveOrganizationId,
  };

  if (
    options.method &&
    options.method.toUpperCase() !== "GET" &&
    options.body
  ) {
    options.data = options.body;
    delete options.body;
  }

  try {
    const response = await instance({
      ...options,
      url: endpoint,
      params,
      headers: options.headers,
    });
    return { data: response.data, error: null };
  } catch (error) {
    console.log(error)
    const standardizedError = {
      status: error.response?.status || 600,
      message: error.response?.data?.message || "generalError",
    };
    if (error.response) {
      console.error("Data:", error.response.data);
      console.error("Status:", error.response.status);
      console.error("Headers:", error.response.headers);
    } else if (error.request) {
      console.error("Request:", error.request);
    } else {
      console.error("Error:", error.message);
    }
    console.error("Config:", error.config);

    return { data: null, error: standardizedError };
  }
};
