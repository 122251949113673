import styled from "styled-components";
import {
  DarkTheme,
  DarkerTheme,
  LightTheme,
  MainGreen,
  White,
} from "./Colors";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 480px;
  background: ${LightTheme};
  margin: 0 auto;
  height: 100vh;
  text-align: right;
  color: ${DarkerTheme};
  font-family: "Rubik";
  padding: 0rem 15px 1rem 15px; // Optional: Adds a little padding on the sides.
  position: relative; // Added this to ensure our BottomBar is positioned
  overflow-x: hidden; // Prevent any child element from overflowing the x-axis
  .cl-card {
    width: 100%;
    display: grid;
    box-shadow: none;
    .cl-headerTitle,
    .cl-headerSubtitle {
      display: none;
    }
  }
`;

export const MobileViewWrapper = styled.div`
  width: 100%;
  max-width: 475px;
  background: ${White};
  border-radius: 15px;
  text-align: center;
  margin-bottom: 7rem;
`;

export const BreakLine = styled.div`
  margin: 1rem 0rem;
`;

export const BigBreakLine = styled.div`
  margin: 2.5rem 0rem;
`;

export const SmallBreakLine = styled.div`
  margin: 0.5rem 0rem;
`;

export const VerticalBreakLine = styled.div`
  margin: 0rem 0.5rem;
`;

export const StyledFlex = {
  width: "100%",
  background: `${DarkTheme}`,
  borderRadius: "15px",
};

export const Option = ({ option }) => (
  <option
    style={{ background: LightTheme, fontSize: "1rem", color: DarkTheme }}
    value={option}
  >
    {option}
  </option>
);

export const BoxHeader = styled.label`
  font-weight: 500 !important;
  text-align: right;
  direction: rtl;
  color: ${DarkerTheme};
  margin-bottom: 0.5rem;
  display: block;
`;

export const Shadow = "17px 10px 22px -9px rgba(0,0,0,0.05)";
export const ShadowBottom = "-2px -5px 5px 0px rgba(165,165,165,0.15)";


export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${(props) => (props.width ? props.width : "100%")};
  margin: ${(props) => props.margin};
  align-content: flex-start;
  box-shadow: ${(props) => (props.shadow ? Shadow : "")};
  ${(props) => {
    if (props.justify) {
      return { justifyContent: props.justify };
    } else {
      return { justifyContent: "flex-start" };
    }
  }}
  align-items: ${(props) => (props.alignItems ? props.alignItems : "stretch")};
  background: ${(props) => (props.background ? props.background : White)};
  cursor: ${(props) => (props.cursor ? props.cursor : "")};
  border-radius: ${(props) => props.radius};
  padding: ${(props) => props.padding};
  border: ${(props) => props.border};
  flex-direction: ${(props) => props.flexDirection};
  dir: rtl;
  ${(props) => {
    if (props.isStyled) {
      return StyledFlex;
    }
  }}
`;

export const internalBoxStracture = `
    padding:1rem;
    border-radius:15px;
    margin:.2rem;
	color: white;

`;
export const BoxSize = styled.div`
  dir: rtl;
  flex: ${(props) => props.flexSize};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-size: ${(props) => props.fontSize};
  border-radius: ${(props) => props.borderRadius};
  text-align: ${(props) => props.textAlign};
  background: ${(props) =>
    props.isInvisible ? "" : props.background ? props.background : White};
  box-shadow: ${(props) => (props.shadow ? Shadow : "")};
  ${internalBoxStracture}
  color: ${(props) => props.color};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  display: ${(props) => props.display};
  justify-content: ${(props) => props.justifyContent};

  ${(props) =>
    props.header
      ? {
          background: MainGreen,
          color: "black",
          position: "relative",
          border: "none",
        }
      : ""};
`;
