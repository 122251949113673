import React, { useEffect, useState } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer, Tooltip } from "recharts";
import { DarkerTheme, MainBlue } from "../../Styles/Colors";

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
  } = props;

  return (
    <g>
      <text
        fontSize={22}
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        fill={DarkerTheme}
      >
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

const MyPieChart = ({ dataObject }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState([]);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    setData(dataObject);
  }, [dataObject]);

  return (
    <ResponsiveContainer
      width={320}
      height={280}
      style={{ display: "inline-block" }}
    >
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={90}
          outerRadius={130}
          fill={MainBlue}
          dataKey={"value"}
          onMouseEnter={onPieEnter}
        />
        <Tooltip formatter={(value) => `${value}%`} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default MyPieChart;
