/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Input } from "@chakra-ui/react";
import { List } from "./Style";
import { useDebounce } from "../../Utils/Hooks/useDebounce";
import { DarkerTheme, InputColor, LightTheme } from "../../Styles/Colors";

const AutoCompleteInput = ({
  suggestions,
  handleAutoCompleteSelect,
  control,
  inputDetails,
  clear,
  setIsValidSelection,
  isValidSelection,
  handleValidation,
  isEditMode
}) => {
  const [filtered, setFiltered] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState(
    inputDetails && (inputDetails.defaultValue || "")
  );
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [isDisabled, setIsDisabled] = useState(false);


  useEffect(() => {
    if (isEditMode && inputDetails && inputDetails.defaultValue !== "") {
      setSearchTerm(inputDetails.defaultValue);
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [inputDetails]);

  useEffect(() => {
    if (clear) {
      setSearchTerm("");
    }
  }, [clear]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      const input = debouncedSearchTerm.normalize("NFC");
      const newFilteredSuggestions = suggestions.filter((suggestion) =>
        suggestion.name
          .normalize("NFC")
          .toLowerCase()
          .includes(input.toLowerCase())
      );

      setFiltered(newFilteredSuggestions);
      setIsShow(true);
    } else {
      setFiltered([]);
      setIsShow(false);
    }
  }, [debouncedSearchTerm, suggestions]);

  const onChoosen = useCallback(
    (name, e, product) => {
      setIsShow(false);
      setSearchTerm(e.currentTarget.innerText);
      handleAutoCompleteSelect(name, e.currentTarget.innerText, product);
      handleValidation();
    },
    [handleAutoCompleteSelect, handleValidation]
  );

  const renderAutocomplete = () => {
    if (!isDisabled && isShow && searchTerm) {
      if (filtered.length) {
        return (
          <ul style={List}>
            {filtered.map((suggestion) => (
              <li
                key={suggestion.name}
                id={suggestion.name}
                onClick={(e) => {
                  onChoosen(inputDetails.name, e, suggestion);
                }}
                style={{ cursor: "pointer" }}
              >
                {suggestion.name}
              </li>
            ))}
          </ul>
        );
      }
    }
    return <></>;
  };

  return (
    <>
      <Input
        type={inputDetails.type}
        name={inputDetails.name}
        color={DarkerTheme}
        bg={InputColor}
        disabled={isDisabled} 
        borderRadius={"15px"}
        textAlign={"right"}
        width={inputDetails.width}
        control={control}
        value={searchTerm}
        defaultValue={inputDetails.value ? inputDetails.value : ""}
        placeholder={inputDetails.placeholder}
        onChange={(e) => {
          if (!isDisabled) {
            setSearchTerm(e.target.value);
            if (isValidSelection) setIsValidSelection(false);
          }
        }}
        border="none"
        id={inputDetails.name}
        background={LightTheme}
      />
      {renderAutocomplete()}
    </>
  );
};

export default AutoCompleteInput;
