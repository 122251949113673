import { Box } from "@chakra-ui/react";
import React from "react";
import { Shadow } from "../../Styles/Styles";
import { useTranslation } from "react-i18next";

const NotFoundError = ({ onRetry, t, error }) => (
  <Box shadow={Shadow} padding={"1rem"}>
    <p>{t(error.message)}</p>
    {onRetry && <button onClick={onRetry}>Retry</button>}
  </Box>
);

const UnauthorizedError = ({ onLogin, t, error }) => (
  <Box shadow={Shadow} padding={"1rem"}>
    <p>{t(error.message)}</p>
    {onLogin && <button onClick={onLogin}>Login</button>}
  </Box>
);

const InternalServerError = ({ t, error }) => (
  <Box shadow={Shadow} padding={"1rem"}>
    <p>{t(error.message)}</p>
  </Box>
);

const GeneralError = ({ t, error }) => (
  <Box shadow={Shadow} padding={"1rem"}>
    <p>{t(error.message)}</p>
  </Box>
);

// Error mapping object
const ErrorComponents = ({ error }) => {
  const { t } = useTranslation();
  if (error.status === 404) return <NotFoundError t={t} error={error} />;
  if (error.status === 401) return <UnauthorizedError t={t} error={error} />;
  if (error.status === 500) return <InternalServerError t={t} error={error} />;
  if (error.status === 600) return <GeneralError t={t} error={error} />;
  // ... add more as needed
};

export default ErrorComponents;
