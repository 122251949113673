import { Button } from "@chakra-ui/react";

export const ActionButton = ({ onClick, button }) => {
	return (
		<Button
			colorScheme={button && button.colorScheme}
			fontWeight={"400"}
			isDisabled={button && button.disable}
			background={button && button.bg}
			borderRadius={"25px"}
			color={button && (button.color  || "white")}
			margin={"0rem .5rem"}
            onClick={() => onClick()}
			leftIcon={button && button.icon}
		>
			{button && button.content}
		</Button>
	);
};

export default ActionButton;
