/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { useSession } from "@clerk/clerk-react";
import { useTranslation } from "react-i18next";
import { baseFetch } from "../../../Utils/baseFetch";
import { calculatePercentages } from "../../../Components/Charts/utils/calculatePercentages";
import ErrorComponents from "../../../Modules/Errors/ErrorsComponent";
import { Box, Select } from "@chakra-ui/react";
import { LightTheme } from "../../../Styles/Colors";
import { BoxSize, BreakLine, Flex } from "../../../Styles/Styles";
import MyBarChart from "../../../Components/Charts/BarChart";
import MyPieChart from "../../../Components/Charts/PieChart";

const selectOptions = [
  { name: "categories", label: "displayCategoriesDistebution" },
  { name: "assignees", label: "displayAssigneesDistebution" },
];

const Statistics = () => {
  const [statistics, setStatistics] = useState({
    categories: [],
    assignees: [],
    totalSpent: 0,
  });
  const { session } = useSession();
  const [error, setError] = useState(null);
  const [currentView, setCurrentView] = useState(selectOptions[0].name);
  const { t, i18n } = useTranslation();

  const getStatistics = async () => {
    try {
      const { data, error } = await baseFetch("/api/lists/statistics", session);
      if (error) return setError(error);
      const categoriesStatistics = calculatePercentages(
        t,
        data.categoryDistribution,
        "categories"
      ).map((entry) => ({
        ...entry,
        fill: entry.background,
      }));
      const assigneeStatistics = calculatePercentages(
        t,
        data.assigneeDistribution
      ).map((entry) => ({
        ...entry,
        fill: entry.background,
      }));
      setStatistics({
        ...statistics,
        assignees: assigneeStatistics,
        categories: categoriesStatistics,
      });
    } catch (error) {
      console.error("Error creating a new list:", error);
    }
  };

  useEffect(() => {
    getStatistics();
  }, []);

  const toggleView = useCallback((e) => {
    setCurrentView(e.target.value);
  }, []);

  if (error) {
    return <ErrorComponents error={error} />;
  }

  return (
    <Flex
      style={{
        border: `1px solid ${LightTheme}`,
        borderRadius: "15px",
      }}
      margin="1rem 0rem"
      padding=".5rem"
      alignItems="center"
      position="relative"
    >
      <Select
        dir={i18n.language === "he" ? "rtl" : "ltr"}
        onChange={toggleView}
        value={currentView}
      >
        {selectOptions.map((view, i) => (
          <option key={i} value={view.name}>
            {t(view.label)}
          </option>
        ))}
      </Select>
      <Box width="100%" mt="2rem" position={"relative"} display="inline-block">
        {currentView === "categories" && <MyPieChart dataObject={statistics[currentView]} />}
        {currentView === "assignees" &&  <MyBarChart dataObject={statistics[currentView]} />}

       
      </Box>
    </Flex>
  );
};

export default Statistics;
