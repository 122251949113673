import { Button, Image } from "@chakra-ui/react";
import { BoxSize } from "../../Styles/Styles";

const ComponentsCard = ({ card }) => {
  const { text, imageSource, button, imageSize } = card;

  return (
    <div style={{width:"100%", padding:".5rem"}}>
      <Button
        bg={button.bg}
        colorScheme={button.colorScheme}
        borderRadius="15px"
        width="100%"
        height="3rem"
        textAlign={"start"}
        padding={"2rem"}
        isDisabled={button.disabled}
        onClick={button.onClick && button.onClick}
        position={"relative"}
      >
        <BoxSize flexSize="2 0 70%" isInvisible={true}>
          {text && text.map((content, idx) => <p key={idx}>{content}</p>)}
        </BoxSize>
        <Image
          src={imageSource}
          style={{
            position: "absolute",
            display: "inline-block",
            width: imageSize,
            right: ".5rem",
          }}
        />
      </Button>
    </div>
  );
};

export default ComponentsCard;
