/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DarkerTheme, MainBlue, MainGreen, White } from "../../Styles/Colors";
import { Link } from "react-router-dom";
import { Box, Image } from "@chakra-ui/react";
import Logo from "../../Assets/Images/logo.png";
import { UserButton, useSession } from "@clerk/clerk-react";
import { FaListCheck, FaUsers } from "react-icons/fa6";
import { FaHistory } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { ShadowBottom } from "../../Styles/Styles";
import { baseFetch } from "../../Utils/baseFetch";

const NavBar = styled.div`
  background: ${White};
  padding: 0.2rem;
  width: 100%;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  display: flex;
  justify-content: space-around; // evenly distribute items
  align-items: center;
  position: fixed;
  bottom: -0.5rem;
  left: 0;
  right: 0;
  z-index: 10;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  box-shadow:${ShadowBottom};
`;

const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${DarkerTheme};
  margin-top: 0.4rem;
`;

const Icon = styled.div`
  background: transparent;
  padding: 0;
  border-radius: 50%;
  width: 2rem;
  position: relative;
  display: flex;
  justify-content: center;
  &.central {
    padding: 0;
    width: 4rem;
    height: 4rem;
    background: white;
    position: absolute;
    left: 50%;
    top: -50%;
    transform: translateX(-50%);
    z-index:10;
    margin:0;
  }

  &.central2 {
    padding: 0;
    width: 4.1rem;
    height: 4.1rem;
    margin:0;
    background: ${MainGreen};
    position: absolute;
    left:50%;
    top: -48%;
    transform: translate(-50%);
    z-index:9;
  }

  &.central3 {
    padding: 0;
    width: 4.2rem;
    height: 4.2rem;
    margin:0;
    background: ${MainBlue};
    position: absolute;
    left: 50%;
    top: -52%;
    transform: translate(-50%);
  }


`;

const Label = styled.span`
  margin-top: 2px;
  margin-bottom: 0.4rem;
  font-size: 12px;
`;

const StyledImage = styled(Image)`
  width: 2.1rem;
  height: 2.8rem;
  margin-bottom: 0.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const BottomNav = () => {
  const { t } = useTranslation();
  const [currentList, setCurrentList] = useState({});
  const [error, setError] = useState(null);
  const { session } = useSession();

  const fetchCurrentList = async () => {
    try {
      const { data, error } = await baseFetch("/api/lists/latest", session);
      if (error) return setError(error);
      if (data !== "") {
        setCurrentList(data);
      }
    } catch (error) {
      console.error("Error fetching the current list:", error);
    }
  };

  useEffect(() => {
    fetchCurrentList();
    // getStatistics();
  }, []);

  const navItems = [
    {
      icon: <FaUsers size="1.3rem" color={MainBlue} />,
      label: "members",
      link: "/invite",
    },
    {
      icon: <StyledImage src={Logo} width="2rem" mb=".4rem" />,
      label: "",
      link: "/",
      className: "central",
    },
    {
      className: "central2",
    },
    {
      className: "central3",
    },
    {
      icon: <FaListCheck size="1.3rem" color={MainBlue} />,
      label: "list",
      link: `/list/${currentList._id ? currentList._id : ""}`,
    },
    {
      icon: <FaHistory size="1.3rem" color={MainBlue} />,
      label: "history",
      link: "/lists",
    },
  ];

  return (
    <NavBar>
      <Box mb=".5rem">
        <UserButton />
      </Box>

      {navItems.map((item, i) => (
        <Link to={item.link}>
        <NavItem>
          <Icon className={item.className}>{item.icon}</Icon>
          <Label>{t(item.label)}</Label>
        </NavItem>
        </Link>
      ))}
    </NavBar>
  );
};

export default BottomNav;
