// theme.js
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  components: {
    Select: {
      baseStyle: (props) => ({
        icon: {
          left: props.dir === 'rtl' ? '0.5rem' : undefined,
          right: props.dir === 'rtl' ? undefined : '0.5rem',
        },
      }),
    },
  },
});

export default theme;
