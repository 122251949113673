import { FaSearch } from "react-icons/fa"
import InputComp from "./Input"
import {MainBlue } from "../../Styles/Colors";

const SearchInput = ({input, onSearch}) => {
    return (
        <InputComp onChange={onSearch} input={{ placeholder: "...חפש", bg:input && input.bg, icon:<FaSearch color={MainBlue} /> }}  />

    )
}
export default SearchInput;