import { MainBlue } from "../../../Styles/Colors";
import { categories } from "../../../mocks/categories";

const categoryBackgrounds = categories.reduce((acc, category) => {
  acc[category.englishName] = category.backgroundColor;
  return acc;
}, {});

export const calculatePercentages = (t, dataObject, action) => {
  const totalCount = Object.values(dataObject).reduce(
    (acc, value) => acc + value,
    0
  );
  return Object.entries(dataObject).map(([name, count]) => {
    if (action === "categories") {
      return {
        name: t(name), // Translate the name key
        value: Number(((count / totalCount) * 100).toFixed(2)), // Two decimal places
        background:
          action === "categories" ? categoryBackgrounds[name] : MainBlue,
      };
    }
    return {
      name, // Translate the name key
      [t("percentages")]: ((count / totalCount) * 100).toFixed(2), // Two decimal places
      background: MainBlue,
    };
  });
};
