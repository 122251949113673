import DisplayedProducts from "./DisplayedProducts";

export const tabs = (
  searchTerm,
  filteredProducts,
  products,
  onRemoveProduct,
  onCheckProduct,
  loading,
  onEditProduct,
  assigneeGroupedProducts,
  t
) => {
  return [
    {
      label: t("all"),
      content: () => (
        <DisplayedProducts
          currentList={searchTerm ? filteredProducts : products}
          onRemoveProduct={onRemoveProduct}
          onCheckProduct={onCheckProduct}
          loading={loading}
          onEditProduct={onEditProduct}
        />
      ),
    },
    ...Object.keys(assigneeGroupedProducts).map((assignee) => ({
      label: assignee,
      content: (
        <DisplayedProducts
          currentList={assigneeGroupedProducts[assignee]}
          onRemoveProduct={onRemoveProduct}
          onCheckProduct={onCheckProduct}
          loading={loading}
          onEditProduct={onEditProduct}
        />
      ),
    })),
  ];
};
