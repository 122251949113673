import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
} from "@chakra-ui/react";
import { MainPink } from "../../Styles/Colors";

const ModalWrapper = ({
	onSave,
	modalInfo,
	isOpen,
	onOpen,
	onClose,
	buttons,
	clearInputs
}) => {
	return (
		<>
			<Modal  isOpen={isOpen} onClose={() => {
				clearInputs && clearInputs();
				onClose()
			}}>
				<ModalOverlay />
				<ModalContent textAlign="right" borderRadius="15px" width="90%" margin="auto">
					<ModalHeader textAlign="right">{modalInfo && modalInfo.header}</ModalHeader>
					<ModalCloseButton color={MainPink} left="0" />
					<ModalBody textAlign="right">{modalInfo && modalInfo.body}</ModalBody>
					<ModalFooter textAlign="right">{buttons}</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ModalWrapper;
