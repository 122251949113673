import { Input, InputLeftElement, InputGroup } from "@chakra-ui/react";
import { FormControl, FormLabel, FormHelperText } from "@chakra-ui/react";
import {  LightTheme, MainPink } from "../../Styles/Colors";
import { useState } from "react";

const InputComp = ({ input, onChange }) => {
	const [error, setError] = useState(false);

	const handleInputChange = (value) => {
		onChange(value);
	};

	return (
		<FormControl padding=".5rem">
			<FormLabel dir="rtl">
				{input && input.label} {input && input.tooltip}
			</FormLabel>
			<InputGroup>
				{input && input.icon && (
					<InputLeftElement pointerEvents="none">{input.icon}</InputLeftElement>
				)}
				<Input
					placeholder={input && input.placeholder}
					type={input && (input.type || "text")}
					bg={(input && input.bg) || LightTheme}
					borderRadius={"15px"}
					padding="1.3rem"
					textAlign="right"
					required={input && input.required}
					value={input && (input.defaultValue || input.value)}
					onChange={(e) => {
						const valid =
							input.validationFunc && input.validationFunc(e.target.value);
						setError(true);
						if (valid) setError(false);
						handleInputChange(e.target.value);
					}}
				/>
			</InputGroup>

			{error && (
				<>
					<FormHelperText color={MainPink}>
						{input && input.requiredMessage}
					</FormHelperText>
				</>
			)}
		</FormControl>
	);
};

export default InputComp;
