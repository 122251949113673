import React from "react";
import { BoxSize, BreakLine, Flex, SmallBreakLine } from "../../Styles/Styles";
import { DarkTheme } from "../../Styles/Colors";
import ProductCard from "../../Components/Cards/ProductCards";
import ProductSkeleton from "../../Components/Skeletons/ProductSkeleton";
import { useTranslation } from "react-i18next";

const DisplayedProducts = ({
  currentList,
  onRemoveProduct,
  onEditProduct,
  onCheckProduct,
  loading,
}) => {
  const { t } = useTranslation();

  const groupedByCategory = (products) => {
    if (!Array.isArray(products)) {
      console.error("Expected products to be an array, got:", products);
      return {};
    }
    return products.reduce((acc, product) => {
      const category = product.productDetails?.category;
      if (category) {
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(product);
      } else {
        console.warn("No category found for product", product);
      }
      return acc;
    }, {});
  };

  const groupedProducts = groupedByCategory(currentList || []);

  if (loading) {
    return (
      <>
        <ProductSkeleton />
        <ProductSkeleton />
      </>
    );
  }

  return (
    <>
      {!loading && currentList?.length === 0 && (
        <Flex radius="15px" flexDirection="column" shadow>
          <BoxSize width="100%" color={DarkTheme} fontSize="1.1rem">
            {t("oopsDidntFindProducts")}:(
          </BoxSize>
        </Flex>
      )}
      {currentList?.length > 0 && (
        <Flex background="transparent" flexDirection="column">
          {Object.entries(groupedProducts).map(([category, products]) => (
            <React.Fragment key={category}>
              <BreakLine />
              <SmallBreakLine />
              {products.map((prod, i) => (
                <React.Fragment key={prod._id}>
                  <ProductCard
                    product={prod}
                    onRemove={onRemoveProduct}
                    onCheck={onCheckProduct}
                    onEdit={onEditProduct}
                  />
                  <SmallBreakLine />
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </Flex>
      )}
    </>
  );
};

export default DisplayedProducts;
