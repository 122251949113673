import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { IconButton } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { DarkerTheme, LightTheme, White } from "../../../Styles/Colors";

const HamburgerMenu = ({ icon, menuItems }) => {
  return (
    <Menu placement="bottom-end" size="lg" >
      <MenuButton
        as={IconButton}
        border="none"
        aria-label="Options"
        icon={icon}
        variant="outline"
        boxSize="3rem"
      />
      <MenuList bg={White} borderRadius={"15px"} padding=".5rem .5rem .5rem .5rem">
        {menuItems.map((item, index) => {
          if (item.nav) {
            return (
              <Link to={item.to} key={index}>
                <MenuItem
                  dir="rtl"

                  icon={item.icon}
                  bg={White}
                  color={DarkerTheme}
                  _hover={{ bg: LightTheme, borderRadius:"15px" }}
                  onClick={item.onClick && item.onClick}
                >
                  {item.name}
                </MenuItem>
              </Link>
            );
          }
          return (
            <MenuItem
              dir="rtl"
              icon={item.icon}
              bg={White}
              color={DarkerTheme}
              _hover={{ bg: LightTheme, borderRadius:"15px" }}
              onClick={item.onClick && item.onClick}
            >
              {item.name}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export default HamburgerMenu;
