/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { debounce } from "../../Utils/Hooks/useDebounce";
import { stateFields } from "./fields";
import { BoxHeader, BoxSize, BreakLine, Option } from "../../Styles/Styles";
import AutoCompleteInput from "../Autocomplete/AutoComplete";
import { Select } from "@chakra-ui/react";
import { DarkTheme, LightTheme, MainBlue } from "../../Styles/Colors";
import InputComp from "../Input/Input";
import ActionButton from "../Buttons/ActionButton";
import CounterInput from "../Input/CounterInput";
import { baseFetch } from "../../Utils/baseFetch";
import { isEmpty } from "../../Utils/objectEmpty";
import { useOrganizationList, useSession } from "@clerk/clerk-react";
import { extractMembers } from "./utils/extractMembers";

const AddProductForm = ({ handleForm, product, isEditMode }) => {
  const [disableButton, setDisableButton] = useState(true);
  const [allProducts, setAllProducts] = useState([]);
  const [isValidSelection, setIsValidSelection] = useState(false);
  const [assignees, setAssignees] = useState([]);
  const { control, reset, getValues } = useForm({
    defaultValues: {
      name: { prodName: "", prodId: "", category: "" },
      amount: 0,
      desc: "",
      assignee: assignees[0],
    },
  });
  const [clear, setClear] = useState(false);
  const { session } = useSession();
  const [error, setError] = useState(null);
  const { userMemberships } = useOrganizationList({
    userMemberships: {
      infinite: true,
    },
  });

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        let membersRaw =
          await userMemberships.data[0].organization.getMemberships();
        let members = extractMembers(membersRaw);
        setAssignees(members);
        reset({ ...getValues, assignee: members[0] });
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    };
    if (!isEmpty(userMemberships) && userMemberships.data) {
      fetchMembers();
    }
  }, [userMemberships.data, reset, getValues]);

  useEffect(() => {
    if (!isEmpty(product)) {
      reset({
        name: {
          prodName: product.productDetails.name,
          prodId: product.productDetails._id,
          prodCat: product.cateogry,
        },
        amount: product.amountOfUnits,
        desc: product.description,
        assignee: product.assignee,
      });
      setIsValidSelection(true);
    }
  }, [product, reset, assignees]);

  const handleValidation = useCallback(() => {
    const nameValue = getValues("name");
    const amountValue = getValues("amount");

    const isNonZeroAmount = Number(amountValue) !== 0;
    const nameValidation = stateFields()[0].validationFunc(nameValue);
    const amountValidation =
      stateFields()[1].validationFunc(amountValue) && isNonZeroAmount;

    if (nameValidation && amountValidation && isValidSelection) {
      return setDisableButton(false);
    }
    return setDisableButton(true);
  }, [getValues, isValidSelection]);

  const handleAutoComplete = useCallback(
    (key, value, product) => {
      reset({
        ...getValues(),
        name: {
          prodName: value,
          prodId: product._id,
          prodCat: product.category,
        },
      });
      setIsValidSelection(true);
      handleValidation();
    },
    [getValues, handleValidation, reset]
  );

  const fetchAllProducts = async () => {
    try {
      const { data, error } = await baseFetch(`/api/products/all`, session);
      if (error) return setError(error);
      return data;
    } catch (error) {
      console.error("Error fetching products:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const products = await fetchAllProducts();
      setAllProducts(products);
    };
    fetchProducts();
  }, []);

  const handleDebounce = useMemo(() => {
    return debounce((key, value) => {
      reset({ ...getValues(), [key]: value });
    }, 300);
  }, [getValues, reset]);

  const stateFieldsData = stateFields(
    handleDebounce,
    handleAutoComplete,
    getValues
  );

  const handleCountChange = (newCount) => {
    reset({ ...getValues(), amount: newCount });
    handleValidation();
  };

  const handleSave = async () => {
    const formData = {
      name: getValues("name.prodName"),
      cateogry: getValues("name.prodCat"),
      id: getValues("name.prodId"),
      amount: getValues("amount"),
      desc: getValues("desc"),
      assignee: getValues("assignee"),
    };
    handleForm(formData);
  };

  return (
    <form>
      {stateFieldsData.map((section, i) => {
        if (section.type === "autoComplete") {
          return (
            <>
              <BoxSize
                flexSize={section.flexWidth}
                style={{ textAlign: "left" }}
                padding="0"
              >
                <BoxHeader>
                  {section.label}
                  {section.tooltip}
                </BoxHeader>
                <AutoCompleteInput
                  clear={clear}
                  suggestions={allProducts}
                  control={control}
                  isEditMode={isEditMode}
                  inputDetails={section}
                  isValidSelection={isValidSelection}
                  setIsValidSelection={setIsValidSelection}
                  handleAutoCompleteSelect={handleAutoComplete}
                  handleValidation={handleValidation}
                />
              </BoxSize>
              <BreakLine />
            </>
          );
        }
        if (section.type === "select") {
          return (
            <BoxSize flexSize={section.flexWidth} padding="0">
              <BoxHeader textAlign="right">
                {section.boxHeader}
                {section.tooltip}
              </BoxHeader>
              <Select
                type={section.type}
                size="md"
                textAlign={"right"}
                id={section.name}
                name={section.name}
                value={getValues(section.name)}
                onChange={(e) => {
                  reset({
                    ...getValues(),
                    [section.name]: e.target.value,
                  });
                  handleValidation();
                }}
                color={DarkTheme}
                style={{
                  background: LightTheme,
                  borderRadius: "15px",
                  border: "none",
                }}
                control={control}
              >
                {assignees.map((offset) => (
                  <Option option={offset} />
                ))}
              </Select>
            </BoxSize>
          );
        }
        if (section.type === "number") {
          return (
            <>
              <CounterInput
                input={section}
                handleCountChange={handleCountChange}
              />
              <BreakLine />
            </>
          );
        }
        return (
          <>
            <InputComp
              input={section}
              onChange={(e) => {
                reset({
                  ...getValues(),
                  [section.name]: e,
                });
                handleValidation();
              }}
            />
            <BreakLine />
          </>
        );
      })}
      <BreakLine />
      <BoxSize width="100%" isInvisible padding="0" margin="0">
        <ActionButton
          button={{
            bg: MainBlue,
            colorScheme: "blue",
            content: !isEmpty(product) ? "עדכן מוצר" : "הוסף מוצר",
            disable: disableButton,
          }}
          onClick={handleSave}
        />
      </BoxSize>
    </form>
  );
};

export default AddProductForm;
