import { Blue600, DarkGreen, DarkModeTheme, DarkThemeLight, DarkerThemeOriginal, FadeMainBlue, GreenPallete, LightBlue, LightBluePallete, LightPink, LightPinkPallete, LightYellowPallete, MainBrown, MainGreenBlue, MainGrey, MainOrange, MainPink, MainPurple, MainRed, MainWhite, MainYellow, OffWhitePallete, RedPallete } from "../Styles/Colors";

export const categories = [
    {
      englishName: "Dairy & Eggs",
      hebrewName: "חלב וביצים",
      backgroundColor: MainWhite,
    },
    { englishName: "Beverages", hebrewName: "משקאות", backgroundColor: Blue600 },
    {
      englishName: "Condiments & Spreads",
      hebrewName: "תוספות וממרחים",
      backgroundColor: MainYellow,
    },
    {
      englishName: "Sauces, Dips & Jams",
      hebrewName: "רוטבים, רטבים וריבות",
      backgroundColor: MainRed,
    },
    {
      englishName: "Canned Goods",
      hebrewName: "משומרים",
      backgroundColor: DarkThemeLight,
    },
    {
      englishName: "Grains & Legumes",
      hebrewName: "דגנים וקטניות",
      backgroundColor: MainBrown,
    },
    {
      englishName: "Vegetables",
      hebrewName: "ירקות",
      backgroundColor: DarkGreen,
    },
    {
      englishName: "Pasta",
      hebrewName: "פסטה",
      backgroundColor: LightYellowPallete,
    },
    {
      englishName: "Nuts & Seeds",
      hebrewName: "אגוזים וזרעים",
      backgroundColor: DarkerThemeOriginal,
    },
    {
      englishName: "Cereals",
      hebrewName: "דגני בוקר",
      backgroundColor: MainOrange,
    },
    {
      englishName: "Dried Fruits",
      hebrewName: "פירות יבשים",
      backgroundColor: MainPink,
    },
    {
      englishName: "Pickles",
      hebrewName: "חמוצים",
      backgroundColor: GreenPallete,
    },
    {
      englishName: "Spices & Herbs",
      hebrewName: "תבלינים וצמחייה",
      backgroundColor: MainGreenBlue,
    },
    {
      englishName: "Soups",
      hebrewName: "מרקים",
      backgroundColor: LightBluePallete,
    },
    { englishName: "Snacks", hebrewName: "חטיפים", backgroundColor: MainGrey },
    {
      englishName: "Household Items",
      hebrewName: "פריטי בית",
      backgroundColor: DarkModeTheme,
    },
    {
      englishName: "Cleaning Supplies",
      hebrewName: "ציוד ניקיון",
      backgroundColor: LightBlue,
    },
    { englishName: "Baking", hebrewName: "אפייה", backgroundColor: RedPallete },
    {
      englishName: "Fruits",
      hebrewName: "פירות",
      backgroundColor: LightPinkPallete,
    },
    { englishName: "Oils", hebrewName: "שמנים", backgroundColor: MainPurple },
    {
      englishName: "Hygiene Products",
      hebrewName: "מוצרי היגיינה",
      backgroundColor: OffWhitePallete,
    },
    {
      englishName: "Frozen Food",
      hebrewName: "מזון קפוא",
      backgroundColor: FadeMainBlue,
    },
    {
      englishName: "Desserts",
      hebrewName: "קינוחים",
      backgroundColor: LightPink,
    },
    {
      englishName: "Pet Supplies",
      hebrewName: "ציוד לחיות מחמד",
      backgroundColor: MainBrown,
    },
  ];



 