import { InputColor } from "../../Styles/Colors";

// ul for signup
export const List = {
	background: InputColor,
	listStyle: "none",
	color: "black",
	borderRadius: "15px",
	padding: ".7rem",
	zIndex: "1",
	maxHeight: "8rem",
	width: "100%",
	overflow: "auto",
	textAlign:"right"
};