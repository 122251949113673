/* eslint-disable no-unused-vars */
import React from "react";
import { OrganizationProfile } from "@clerk/clerk-react";
import HeaderWrapper from "../../Components/Headers/Headers";
import { useTranslation } from "react-i18next";


const InvitePage = () => {
  const {t} = useTranslation();
  const pageName = t("inviteMembers");

  return (
    <HeaderWrapper header={pageName}>
      <OrganizationProfile />
    </HeaderWrapper>
  );
};

export default InvitePage;
