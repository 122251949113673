import { useToast } from "@chakra-ui/react";
import { useRef } from "react";

const useSingleToast = () => {
  const toast = useToast();
  const toastIdRef = useRef();

  const showSuccessToast = (description) => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }

    const id = toast({
      title: "הצלחה",
      description,
      status: "success",
      duration: 2000,
      isClosable: true,
      variant: "solid",
    });
    toastIdRef.current = id;
  };

  const showErrorToast = (description) => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }

    const id = toast({
      title: "שגיאה",
      description,
      status: "error",
      duration: 2000,
      isClosable: true,
      variant: "solid",
    });
    toastIdRef.current = id;
  };

  return {
    showSuccessToast,
    showErrorToast,
  };
};

export default useSingleToast;
