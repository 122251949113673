import React, { useRef } from "react";
import styled from "styled-components";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import { MainBlue, MainGrey, White } from "../../Styles/Colors";

const TabContainer = styled.div`
	overflow-x: scroll;
	overflow-y: hidden;
	white-space: nowrap;
	scroll-behavior: smooth;

	&::-webkit-scrollbar {
		display: none;
	}
`;

export const StyledTab = styled(Tab)`
	border-radius: 25px !important;
	background: ${MainGrey};
	color: ${White} !important;
	padding: 6px 12px !important;
	margin: 1rem 0.5rem !important;
	&[aria-selected="true"] {
		background-color: ${MainBlue};
	}
`;

export const StyledTabList = styled(TabList)`
	margin: 0 !important;
	border: none !important;
`;


const ScrollableTabs = (props) => {
    const { tabs, selectedTab, setSelectedTab } = props;
    const tabContainerRef = useRef(null);

    const handleTabClick = (index) => {
        setSelectedTab(index);
        const tab = tabContainerRef.current?.children[0].children[index];
        tab?.scrollIntoView({ behavior: "smooth", inline: "center" });
    };

    return (
        <Tabs variant="enclosed"  isLazy index={selectedTab} onChange={(index) => setSelectedTab(index)} width="100%">
            <TabContainer ref={tabContainerRef} >
                <StyledTabList justifyContent={"center"} >
                    {tabs.map((tab, idx) => (
                        <StyledTab key={idx} onClick={() => handleTabClick(idx)}>
                            {tab.label}
                        </StyledTab>
                    ))}
                </StyledTabList>
            </TabContainer>
            <TabPanels >
                {tabs.map((tab, idx) => (
                    <TabPanel key={idx} pb={"0"} pt={0}>
                        {typeof tab.content === 'function' ? tab.content() : tab.content}
                    </TabPanel>
                ))}
            </TabPanels>
        </Tabs>
    );
};

export default ScrollableTabs;
