import React, { createContext, useState, useContext } from 'react';

const AppContext = createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

const AppProvider = ({ children }) => {
  const [activeList, setActiveList] = useState({});

  return (
    <AppContext.Provider value={{ activeList, setActiveList }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
