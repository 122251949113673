import { Route, Switch } from "react-router-dom";
import Dashboard from "../Pages/Dashboard/Dashboard";
import ListsPage from "../Pages/Lists";
import List from "../Pages/List/List";
import InvitePage from "../Pages/Invite/Invite";


const Routes = () => {
	// const { userState } = useContext(GlobalContext);

	return (
		<Switch>
			<Route exact path="/" component={Dashboard} />
			<Route exact path="/history" component={ListsPage} />
			<Route exact path="/invite" component={InvitePage} />
			<Route exact path="/lists" component={ListsPage} />
			<Route exact path="/product" component={ListsPage} />
			<Route exact path="/list/:id" component={List} />
		</Switch>
	);
};
export default Routes;
