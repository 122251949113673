import styled from "styled-components";
import { LightTheme, MainBlue, White } from "../../Styles/Colors";
import { Button} from "@chakra-ui/react";

export const StyledTable = styled.table`
	width: 100%;
	border-collapse: collapse;
	border-radius: 15px;
	overflow: hidden; // This will help keep the rounded corners for the inner elements
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); // Optional: Add a subtle shadow for depth

	tr {
		cursor:pointer ;
	}

	th,
	td {
		padding: 12px 15px;
		border-bottom: 1px solid #e1e1e1; // Light border for each row
	}

	th {
		background-color: #f7f7f7; // Light background for headers
		position: sticky;
		top: 0;
		z-index: 10;
	}

	tbody tr:hover {
		background-color: ${LightTheme}; // Light hover effect
		
	}
`;

export const TableCell = styled.td`
	overflow: hidden; // Required to make text-overflow work
	text-overflow: ellipsis; // Add ... for overflow texts
	white-space: nowrap; // Prevents the content from wrapping
	max-width: 100px; // Change this to a suitable width, after which ... will be added
`;

export const PaginationWrapper = styled.div`
	margin: 1.2rem 0;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
`;

export const SearchInput = styled.input`
	margin-bottom: 20px;
	padding: 10px 15px;
	border-radius: 15px;
	border: 1px solid #e1e1e1;
	width: 100%;
	box-sizing: border-box;
	transition: border-color 0.3s;

	&:focus {
		border-color: #007bff; // Highlight color when the input is focused
		outline: none;
	}
`;


export const TableWrapper = styled.div`
	background:${White};
	border-radius:15px;
	padding:1rem;
`

export const TableButton = styled(Button)`
	background: ${MainBlue} !important;
    padding: 6px 12px !important;
    border-radius: 15px !important;
    color: white ;
`